<template>
    <div class="hstack gap-2 align-items-end">
        <div class="vstack gap-1">
            <div class="d-flex justify-content-between">
                <span class="fw-semibold">
                    {{ value }}
                </span>
                <span class="small-text text-body-secondary" v-if="text">
                    {{ text }}
                </span>
            </div>
            <b-progress :variant="variant" height=".5em" :value="value || 0" :max="total || 0"></b-progress>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'value', 'total', 'text', 'variant'
    ]
}
</script>