<script>
  import { Bar } from 'vue-chartjs'

  export default {
    extends: Bar,
    props: [
      "labels",
      "data",
      "backgroundColorArray",
      "backgroundClassArray",
      "displayLegend",
      "xAxesHide",
      "yAxesHide",
      "xAxesColor",
      "yAxesColor",
    ],
    data () {
      return {
        chartData: {
          labels: this.labels,
          datasets: [{
            borderWidth: 0,
            backgroundColor: this.backgroundColorArray || [],
            hoverBackgroundColor: this.hoverBackgroundColorArray || [],
            data: this.data || [1000, 500, 1500]
          }]
        },
        options: {
          legend: {
            display: this.displayLegend,
            labels: {
              generateLabels: chart => {
                return chart.data.labels.map((label, index) => ({
                  text: label,
                  fillStyle: chart.data.datasets[0].backgroundColor[index]
                }))
              }
            },
            position: "bottom"
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  display: !this.yAxesHide,
                  beginAtZero: true,
                  fontColor: this.yAxesColor || '#eaeaea'
                },
              }
            ],
            xAxes: [
              {
                ticks: {
                  display: !this.xAxesHide,
                  fontColor: this.xAxesColor || '#eaeaea'
                },
              }
            ]
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      }
    },
    mounted () {
      this.setColor()
      this.renderChart(this.chartData, this.options)
    },
    methods: {
      setColor() {
        if(!this.backgroundColorArray?.length && this.backgroundClassArray?.length) {
          for(const classe of this.backgroundClassArray) {
            const a = document.getElementsByClassName(classe)[0];
            const styles = window.getComputedStyle(a);
            this.chartData.datasets.at(0)?.backgroundColor.push(styles.backgroundColor)
          }
        }
      }
    }
  }
</script>