<script>
  import { Doughnut } from 'vue-chartjs'

  export default {
    extends: Doughnut,
    props: [
      "labels",
      "data",
      "backgroundColorArray",
      "backgroundClassArray",
      "displayLegend"
    ],
    data () {
      return {
        chartData: {
          labels: this.labels,
          datasets: [{
            borderWidth: 0,
            backgroundColor: this.backgroundColorArray || [],
            data: this.data || [0]
          }]
        },
        options: {
          legend: {
            display: this.displayLegend
          },
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 75
        }
      }
    },
    mounted () {
      this.setColor()
      this.renderChart(this.chartData, this.options)
    },
    methods: {
      setColor() {
        if(!this.backgroundColorArray?.length && this.backgroundClassArray?.length) {
          for(const classe of this.backgroundClassArray) {
            const a = document.getElementsByClassName(classe)[0];
            const styles = window.getComputedStyle(a);
            this.chartData.datasets.at(0)?.backgroundColor.push(styles.backgroundColor)
          }
        }
      }
    }
  }
</script>