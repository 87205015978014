<template>
    <div class="vstack gap-3">
        <span class="d-none bg-green"></span>
        <span class="d-none bg-purple"></span>
        <span class="d-none bg-primary"></span>
        <span class="d-none bg-pink"></span>
        <span class="d-none bg-danger"></span>
        <span class="d-none bg-warning"></span>
        <div class="hstack gap-3 bg-white rounded py-3 px-4 justify-content-between">
            <div class="hstack gap-3">
                <b-iconstack class="bg-green text-white rounded-circle" font-scale="5">
                    <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                    <b-icon stacked icon="window" scale="0.6" variant="white" flip-h></b-icon>
                    <b-icon stacked icon="distribute-vertical" scale="0.2" variant="white" shift-v="-1"></b-icon>
                </b-iconstack>
                <div class="vstack justify-content-center">
                    <div class="h5 m-0">
                        Tráfego
                    </div>
                </div>
            </div>
            <div class="hstack gap-3">
                <b-dropdown ref="dropdownType" right menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text hstack gap-5" variant="white" text="Canais" v-if="channelsGupshup?.length">
                    <b-form-checkbox-group
                        v-model="filters.channels"
                        style="z-index: 1"
                    >
                        <div class="vstack gap-2 p-3 border-bottom" v-for="item in channelsGupshup" :key="item._id">
                            <b-form-checkbox class="text-secondary small-text d-flex align-items-center w-100" role="button" :value="item._id">
                                <div class="ms-1" role="button">{{ item.channelNumber }}</div>
                            </b-form-checkbox>
                        </div>
                    </b-form-checkbox-group>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type == 'operator'">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.operatorType">
                            <b-form-select-option value="department">Departamento</b-form-select-option>
                            <b-form-select-option value="operator">Operador</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type == 'operator' && filters.operatorType == 'department'">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.departmentId">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type == 'channel_manager' || (filters.type == 'operator' && filters.operatorType == 'operator')">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.id" v-if="filters.type == 'channel_manager'">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in managers" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.id" v-else-if="filters.type == 'operator'">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownType">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-dropdown ref="dropdownDate" right menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text hstack gap-2" variant="white" :text="dateFilterText">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="1">
                            <div class="ms-1 small-text">Hoje</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="2">
                            <div class="ms-1 small-text">Esta Semana</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="3">
                            <div class="ms-1 small-text">Este Mês</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="4">
                            <div class="ms-1 small-text">Período</div>
                        </b-form-radio>
                    </div>
                    <div class="vstack gap-3 p-3 pt-0" v-if="filters.date">
                        <div class="vstack gap-3" v-if="filters.date == 4">
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Inicial:">
                                <b-form-input type="date" v-model="filters.firstPeriod"></b-form-input>
                            </b-form-group>
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Final:">
                                <b-form-input type="date" v-model="filters.finalPeriod"></b-form-input>
                            </b-form-group>
                        </div>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-button class="rounded-pill py-0 px-4 text-white fw-semibold" variant="green" @click="getData(1)" :disabled="user?.role == 'admin' ? !filters.date || !filters.channels?.length : !filters.date">
                    Gerar
                </b-button>
            </div>
        </div>
        <div class="bg-white rounded p-4 text-center" v-if="loading">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
        </div>
        <div class="vstack gap-3" v-else-if="traffic.length">
            <b-row class="m-0 gap-3">
                <b-col class="p-0 bg-white rounded p-4">
                    <div class="hstack">
                        <b-iconstack class="invisible w-0" font-scale="4.5">
                            <b-icon stacked icon="circle-fill" variant="pink"></b-icon>
                        </b-iconstack>
                        <div>
                            <div class="h5 m-0 fw-bold text-secondary">
                                Linha do Tempo das Mensagens
                            </div>
                        </div>
                    </div>
                    <div class="vstack align-items-center">
                        <ChartBar2 
                            :data="this.timeline.data || []"
                            :labels="this.timeline.labels || []"
                            :backgroundClassArray="this.timeline.backgroundClassArray || []"
                            :xAxesHide="false"
                            :yAxesHide="false"
                            :yAxesColor="'#aaa'"
                            :xAxesColor="'#aaa'"
                            style="height: 300px"
                        />
                        <div class="text-secondary">
                            <b-icon icon="circle-fill" variant="green"></b-icon>
                            Total de Mensagens
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 bg-white rounded p-4 d-flex flex-column gap-4">
                    <div class="hstack gap-3">
                        <b-iconstack font-scale="4.5">
                            <b-icon stacked icon="circle-fill" variant="pink"></b-icon>
                            <b-icon stacked icon="chat-right" scale="0.4" shift-v=".5" shift-h="1" variant="white"></b-icon>
                            <b-icon stacked icon="square-fill" scale="0.3" shift-v="-.5" shift-h="-.2" variant="pink"></b-icon>
                            <b-icon stacked icon="chat-left-text" scale="0.4" shift-v="-1.25" shift-h="-1" variant="white"></b-icon>
                        </b-iconstack>
                        <div>
                            <div class="h5 m-0 fw-bold text-secondary">
                                Conversações
                            </div>
                            <div class="small-text text-secondary">
                                Mensagens
                            </div>
                        </div>
                    </div>
                    <b-row class="m-0">
                        <b-col class="p-0">
                            <ChartDoughnut :data="conversations && [ conversations.marketing || 0, conversations.authentication || 0, conversations.utility || 0, conversations.service || 0, conversations.outgoingMsg, conversations.incomingMsg ]" :labels="['Marketing', 'Autenticação', 'Utilitário', 'Serviço', 'Sessão Enviada', 'Sessão Recebida']" :backgroundClassArray="['bg-green', 'bg-purple', 'bg-primary', 'bg-pink', 'bg-danger', 'bg-warning']" style="height: 17vw; width: 17vw;"/>
                        </b-col>
                        <b-col class="p-0">
                            <ProgressValueTotal :value="conversations.marketing" :total="conversations?.totalMsgsCount" text="Marketing" variant="green" v-if="conversations?.marketing"/>
                            <ProgressValueTotal :value="conversations.authentication" :total="conversations?.totalMsgsCount" text="Autenticação" variant="purple" v-if="conversations?.authentication"/>
                            <ProgressValueTotal :value="conversations.utility" :total="conversations?.totalMsgsCount" text="Utilitário" variant="primary" v-if="conversations?.utility"/>
                            <ProgressValueTotal :value="conversations.service" :total="conversations?.totalMsgsCount" text="Serviço" variant="pink" v-if="conversations?.service"/>
                            <ProgressValueTotal :value="conversations.outgoingMsg" :total="conversations?.totalMsgsCount" text="Sessão Enviada" variant="danger" v-if="conversations?.outgoingMsg"/>
                            <ProgressValueTotal :value="conversations.incomingMsg" :total="conversations?.totalMsgsCount" text="Sessão Recebida" variant="warning" v-if="conversations?.incomingMsg"/>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="vstack bg-white rounded">
                <div class="hstack justify-content-between p-4">
                    <div class="hstack">
                        <b-iconstack class="invisible w-0" font-scale="4.5">
                            <b-icon stacked icon="circle-fill" variant="pink"></b-icon>
                        </b-iconstack>
                        <div class="h5 m-0 fw-bold text-secondary">
                            Separação de Mensagens
                        </div>
                    </div>
                    <div class="hstack gap-3">
                        <b-dropdown ref="dropdownPerPage" menu-class="p-0 mt-2 w-fit" toggle-class="bg-disabled border-grey text-secondary fw-semibold rounded-pill px-3 py-1 small-text hstack gap-3" :text="`Mostrar ${currentPerPage}`">
                            <div class="vstack gap-2 p-3 w-fit" v-if="filters.date == 1">
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="1" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">1</div>
                                </b-form-radio>
                            </div>
                            <div class="vstack gap-2 p-3 w-fit" v-if="filters.date == 2">
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="3" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">3</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="5" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">5</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="7" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">7</div>
                                </b-form-radio>
                            </div>
                            <div class="vstack gap-2 p-3 w-fit" v-else-if="filters.date == 3 || filters.date == 4">
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="5" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">5</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="10" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">10</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="15" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">15</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" v-model="perPage" name="radio-date" value="20" @change="closeDropdownPerPage">
                                    <div class="ms-1 small-text">20</div>
                                </b-form-radio>
                            </div>
                        </b-dropdown>
                        <div class="fs-5 m-0 bg-green text-white px-4 rounded-pill">
                            <span class="fw-semibold">
                                Valor Total do Período:
                            </span>
                            R$ {{ totalCost.toString() | floatingPoint | decimalSpacing }}
                        </div>
                    </div>
                </div>
                <b-row class="m-0 p-4 border-top border-bottom text-secondary fw-semibold">
                    <b-col class="p-0">
                        Data
                    </b-col>
                    <b-col class="p-0">
                        Templates Enviados
                    </b-col>
                    <b-col class="p-0">
                        Sessão Enviada
                    </b-col>
                    <b-col class="p-0">
                        Sessão Recebida
                    </b-col>
                    <b-col class="p-0">
                        Valor
                    </b-col>
                </b-row>
                <b-row class="trafficRow m-0 p-4 border-bottom text-secondary" v-for="item in traffic" :key="item.date">
                    <b-col class="p-0">
                        {{ item.date | date("DD/MM/YYYY", true) }}
                    </b-col>
                    <b-col class="p-0 hstack gap-2 text-green">
                        <button :id="`templatesData${item.date}`" class="p-0 rounded border-0 bg-transparent lh-1">
                            <b-iconstack font-scale="1">
                                <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                <!-- <b-icon stacked icon="cursor-fill" scale="0.5" variant="white"></b-icon> -->
                            </b-iconstack>
                        </button>
                        <b-tooltip :target="`templatesData${item.date}`" custom-class="top-0" boundary="document" variant="green" placement="bottom">
                            <div class="vstack gap-2 p-2">
                                <div>
                                    <b>Utilitário:</b> {{ item.utility }}
                                </div>
                                <div>
                                    <b>Autenticação:</b> {{ item.authentication }}
                                </div>
                                <div>
                                    <b>Marketing:</b> {{ item.marketing }}
                                </div>
                            </div>
                        </b-tooltip>
                        <span class="lh-1">
                            {{ item.authentication + item.marketing + item.utility }}
                        </span>
                    </b-col>
                    <b-col class="p-0">
                        {{ item.outgoingMsg }}
                    </b-col>
                    <b-col class="p-0">
                        {{ item.incomingMsg }}
                    </b-col>
                    <b-col class="p-0 text-warning">
                        R$ {{ item.totalCost.toString() | floatingPoint | decimalSpacing }}
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-between align-items-center p-4" v-if="trafficCount > currentPerPage">
                    <b-pagination class="m-0"
                        v-model="currentPage"
                        :total-rows="trafficCount"
                        :per-page="currentPerPage"
                        @change="pageChange"
                        :disabled="trafficCount <= currentPerPage"
                    ></b-pagination>
                    <span>
                        <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * currentPerPage) - currentPerPage + 1 : currentPage }} - {{(currentPage * currentPerPage > trafficCount) ? trafficCount : currentPage * currentPerPage}} de {{ trafficCount }} Separação de Mensagens</div>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white rounded p-3 text-center text-secondary" v-else-if="loaded">
            Nenhum dado encontrado!
        </div>
        <FAB :screenshotBtn="true" />
    </div>
</template>

<script>
import api from '../services/apiService'
import FAB from './FAB.vue'
import ChartDoughnut  from './chartDoughnut.vue'
import ProgressValueTotal from './progressValueTotal.vue'
import ChartBar2 from './chartBar2.vue'
import { setTimeout } from 'timers'

export default {
    props: [
        "user"
    ],
    components: {
        FAB, ChartDoughnut, ProgressValueTotal, ChartBar2
    },
    mounted() {
        this.setup()
    },
    data() {
        return {
            channelsGupshup: [],
            filters: {},
            traffic: [],
            timeline: {},
            conversations: null,
            perPage: 10,
            trafficCount: 0,
            totalCost: 0,
            loading: false,
            loadingChannels: false,
            loaded: false,
            currentPage: 1,
            currentperPage: 1,
        }
    },
    computed: {
        dateFilterText() {
            let text = ''
            switch(this.filters.date) {
                case 1:
                case '1':
                    text = 'Hoje'
                    break
                case 2:
                case '2':
                    text = 'Esta Semana'
                    break
                case 3:
                case '3':
                    text = 'Este Mês'
                    break
                case 5:
                case '5':
                    text = 'Este Ano'
                    break
                default:
                    text = 'Período'
            }
            return text
        },
    },
    watch: {
        'filters.date': {
            handler(newValue) {
                if(newValue == 1) {
                    this.perPage = 1
                } else if(newValue == 2) {
                    this.perPage = 7
                } else {
                    this.perPage = 10
                }
            }
        }
    },
    methods: {
        async setup() {
            if(this.user.role == 'admin') {
                this.getChannelsGupshup()
            } else {
                await this.getSecondaryChannels()
                if(!this.user.channel) {
                    setTimeout(() => {
                        if(!this.channelsGupshup?.length)
                            this.filters.channels = [this.user.channel._id]
                    }, 1000);
                } else {
                    if(!this.channelsGupshup?.length)
                        this.filters.channels = [this.user.channel._id]
                }
            }
        },
        async getChannelsGupshup() {
            this.loadingChannels = true

            const resp = await api.getChannelsGupshup()
            console.log({resp})
            if(resp.statusCode == 200) {
                this.channelsGupshup = resp.channels
            } else {
                this.channelsGupshup = []
            }

            this.loadingChannels = false
        },
        async getData(page = 1)  {
            this.loading = true
            // this.filters.channels = [this.user.channel._id]
            console.log(this.filters)
            if(this.perPage != this.currentPerPage)
                this.currentPerPage = this.perPage
            const resp = await api.getGupshupTraffic({ filters: this.filters }, page, this.perPage)
            // console.log({ resp })
            this.loading = false
            if(resp.statusCode == 200) {
                this.traffic = resp.traffic
                this.trafficCount = resp.trafficCount
                this.totalCost = resp.totalCost
                this.conversations = resp.conversations
                if(resp.timeline) {
                    // if(this.filters.date == 1 || this.filters.date == 2) {
                        this.timeline.data = resp.timeline.map(el => el.totalMsgsCount)
                        this.timeline.labels = resp.timeline.map(el => this.$options.filters.capitalize(this.$options.filters.date(el.date, 'DD/MM', true)))
                    // }
                }
                let backgroundClassArray = []
                // eslint-disable-next-line no-unused-vars
                for (const _index in this.timeline.data) {
                    backgroundClassArray.push('bg-green')
                }
                this.timeline.backgroundClassArray = backgroundClassArray
            } else {
                this.traffic = []
                this.trafficCount = 0
                this.totalCost = 0
                this.conversations = null
                this.timeline = {}
            }

            if(!this.loaded)
                this.loaded = true
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            // console.log('resp secChannels',resp)
            if(resp.statusCode != 200) {
                this.channelsGupshup = []
            } else {
                this.channelsGupshup = resp.channels.filter(el => el.apiType == 'gupshup')

                if(this.channelsGupshup?.length && this.user?.channel?.apiType == 'gupshup')
                    this.channelsGupshup.unshift(this.user.channel)
            }
        },
        pageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.getData(e)
            }
        },
        formatPercentage(value, total) {
            let n = ((value / total) || 0) * 100
            if(Number.isInteger(n)) {
                return n
            }
            return n.toFixed(1)
        },
        closeDropdownDate() {
            this.$refs.dropdownDate.hide(true)
        },
        closeDropdownType() {
            this.$refs.dropdownType.hide(true)
        },
        closeDropdownPerPage() {
            this.$refs.dropdownPerPage.hide(true)
            this.getData(1)
        },
    }
}
</script>

<style scoped>
    .trafficRow:last-child {
        border: none !important;
    }
</style>